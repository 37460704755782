//AUTH REDUCER
export const SET_USER_DATA = "SET_USER_DATA"
export const SET_ADDRESSES = "SET_ADDRESSES"
export const SET_CARTS = "SET_CARTS"
export const SET_CARTS_ITEM_DETAILS = "SET_CARTS_ITEM_DETAILS"
export const SET_ORDERS = "SET_ORDERS"
export const SET_WISHLIST = "SET_WISHLIST"

//GENERAL REDUCERS
export const SET_TOAST = "SET_TOAST"
export const SET_CATEGORIES = "SET_CATEGORIES"
export const SET_COLLECTIONS = "SET_COLLECTIONS"
export const SET_TOAST_HEIGHT = "SET_TOAST_HEIGHT"
export const SET_IS_DRAWER = "SET_IS_DRAWER"
export const SET_XENDIT_FUNCTION = "SET_XENDIT_FUNCTION"
export const SET_COUNTRIES = "SET_COUNTRIES"
export const SET_CITY_SUBDISTRICT = "SET_CITY_SUBDISTRICT"
export const SET_BRANDS = "SET_BRANDS"
