import React, { useState } from "react"

//Utils
import Cookies from "js-cookie"
import { AsyncApollo } from "lib/functions"
import { M_DELETE_CARTS_ITEM, M_UPDATE_CARTS } from "lib/graphql"
import { SET_CARTS, SET_CARTS_ITEM_DETAILS } from "lib/redux"
import { ENUM } from "lib/utils/enum"
import { FormatCurrency } from "lib/utils/general-utils"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"
import { dataLayerEvent } from "lib/functions/datalayer-v2"

import imgPreorder from "assets/img/common/logo-preorder.png"

//Components
import { Button, Image } from "components/anti"

const BagItem = ({ product, currency, detailProduct }) => {
  //Utils ******************************************************************* //
  const dispatch = useDispatch()
  const Router = useRouter()
  const isTselProduct = product?.collection?.slug === "telkomsel-poin"
  const { my_carts_item_details } = useSelector((state) => state.auth)
  //Utils ******************************************************************* //

  //States ****************************************************************** //
  const [state, setState] = useState({
    qty: product?.quantity,
    available_stock: product?.stock,
    btnMinusDisable: product?.quantity === 1 ? true : false,
    btnPlusDisable: product?.quantity === product?.stock ? true : false,
  })
  //States ****************************************************************** //
  //Functions *************************************************************** //
  const handleDeleteCart = async (id) => {
    let variables = { lang: Router.locale, id: Number(id), currency }
    let { data, errors } = await AsyncApollo({
      mutation: M_DELETE_CARTS_ITEM,
      variables,
      fetchPolicy: "no-cache",
    })
    if (data?.deleteCart) {
      dispatch({ type: SET_CARTS, payload: data?.deleteCart })
      dispatch({
        type: SET_CARTS_ITEM_DETAILS,
        payload: my_carts_item_details?.map((item, i) => ({
          ...item,
          ...data?.deleteCart?.items[i],
        })),
      })
      // Datalayer
      dataLayerEvent.removeFromCart({
        ...state.dataLayerItem,
        currency,
        subtotal: state.qty * product?.sell_price,
      })
    } else {
      //!NEED ERRROR HANDLING
    }
  }
  const handlePress = async (type) => {
    let { available_stock, qty } = state
    let total
    if (type === "dec") {
      total = qty - 1
    }
    if (type === "inc") {
      total = qty + 1
    }
    if (total === available_stock)
      setState((prev) => ({
        ...prev,
        btnPlusDisable: true,
        qty: total,
        btnMinusDisable: false,
      }))
    if (total < available_stock && total > 1)
      setState((prev) => ({ ...prev, btnPlusDisable: false, qty: total }))
    if (total === 1)
      setState((prev) => ({ ...prev, btnMinusDisable: true, qty: total }))

    //Update New Quantity
    let { data, errors } = await AsyncApollo({
      mutation: M_UPDATE_CARTS,
      variables: {
        lang: Router.locale,
        id: Number(product?.id),
        quantity: total,
        currency,
      },
    })
    if (data?.updateCart?.subtotal) {
      dispatch({ type: SET_CARTS, payload: data?.updateCart }) //Change carts data on redux
      dispatch({
        type: SET_CARTS_ITEM_DETAILS,
        payload: my_carts_item_details?.map((item, i) => ({
          ...item,
          ...data?.updateCart?.items[i],
        })),
      }) //Change carts data on redux
      // Datalayer
      if (type === "inc") {
        dataLayerEvent.addToCart({
          ...state.dataLayerItem,
          currency,
          quantity: 1,
          subtotal: product?.sell_price * 1,
        })
      } else {
        dataLayerEvent.removeFromCart({
          ...state.dataLayerItem,
          currency,
          quantity: 1,
          subtotal: product?.sell_price * 1,
        })
      }
    } else {
      //!NEED ERROR HANDLING
    }
  }
  //Functions *************************************************************** //
  //React Operations *************************************************************************************** //
  React.useEffect(() => {
    if (product) {
      setState((prev) => ({
        ...prev,
        qty: product?.quantity,
        available_stock: product?.stock,
        btnMinusDisable: product?.quantity === 1 ? true : false,
        btnPlusDisable: product?.quantity === product?.stock ? true : false,
      }))
    }
  }, [product])
  React.useEffect(() => {
    if (product && detailProduct) {
      setState((prev) => ({
        ...prev,
        dataLayerItem: {
          ...product,
          ...detailProduct,
          id: product?.sku_id,
          quantity: product?.quantity,
        },
      }))
    }
  }, [detailProduct])
  //React Operations *************************************************************************************** //
  return (
    <div className="product-item">
      <div className="col-image">
        <Image
          src={product.image}
          ratio={ENUM.IMAGE_RATIO}
          className="img-fluid"
        />
      </div>
      <div className="col-content">
        {product?.stock === 0 && (
          <Image
            src={imgPreorder}
            className="preorder-label"
            alt="preorder-label"
          />
        )}
        <p className="name">{product.title}</p>
        <p className="option-1">
          {product?.option_1?.title}: {product?.option_1?.value}
        </p>
        <p className={`option-2 ${!product?.option_2 ? "d-none" : ""}`}>
          {product?.option_2?.title}: {product?.option_2?.value}
        </p>
        <p className="price py-2">
          {FormatCurrency(
            product?.sell_price,
            Cookies.get(process.env.CURRENCY)
          )}
        </p>
        <div className="att">
          {/* <p className="att-item size">Size {product?.option_2?.value}</p> */}
          <div className="att-item qty-adjust">
            <Button
              variant="outline-primary"
              disabled={
                state.btnMinusDisable ||
                (isTselProduct && product?.quantity === 1)
              }
              className="btn-minus"
              onClick={() => handlePress("dec")}
            />
            <input
              type="number"
              className="form-control"
              value={state.qty}
              disabled={isTselProduct}
            />
            <Button
              variant="outline-primary"
              disabled={state.btnPlusDisable || isTselProduct}
              className="btn-plus"
              onClick={() => handlePress("inc")}
            />
          </div>
        </div>

        <div className="btn btn-remove">
          <i
            className="air ai-trash"
            onClick={() => handleDeleteCart(product.id)}
          />
        </div>
      </div>
    </div>
  )
}

export default BagItem
