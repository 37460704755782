import { initializeApollo } from "lib/apollo"
import Cookies from "js-cookie"

const ValidateCookies = () => {
  let accessToken = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)
  if (accessToken) return { token: accessToken }
  else return { token: guestToken }
}
/**
 *
 * @param {*} opt
   Sample Opt: { mutation: Q_CATALOGS, variables: { id: "1" }, fetchPolicy: "no-cache" }
 * @param {*} token
 * @returns
 */
export const AsyncApollo = async (opt, token) => {
  try {
    let client
    // let { query, mutation } = opt
    let query = opt?.query
    let mutation = opt?.mutation
    if (token) client = initializeApollo(token)
    else client = initializeApollo(ValidateCookies().token)
    let fetch
    if (client) {
      if (query) fetch = client.query({ ...opt, errorPolicy: "all" })
      else if (mutation) fetch = client.mutate({ ...opt, errorPolicy: "all" })
      else
        return { errors: { message: "Need query, example: query / mutation " } }
      let { data, errors } = await fetch
      if (data) return { data, errors: null }
      else {
        return { errors, data: null }
      }
    } else {
      return { data: null, errors: null }
    }
  } catch (error) {
    return { errors: error, data: null }
  }
}
export * from "./mutation"
export * from "./query"
export * from "lib/graphql"
