import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector"
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector"

import { toastHandling } from "lib/utils/toast-handling"
import { SET_TOAST } from "lib/redux"
import { injected } from "../utils/connecters"
import useEagerConnect from "./useEagerConnect"
import useInactiveListener from "./useInactiveListener"
import Cookies from "js-cookie"

const useConnectWallet = () => {
  // const { connector, account, activate, error, deactivate } = useWeb3React()
  // const dispatch = useDispatch()

  // /** used to keep the wallet connected */
  // const triedEagerConnect = useEagerConnect()
  // const [activatingConnector, setActivatingConnector] = useState()
  // useInactiveListener(!triedEagerConnect || !!activatingConnector)
  // useEffect(() => {
  //   if (activatingConnector && activatingConnector === connector) {
  //     setActivatingConnector(undefined)
  //   }
  // }, [activatingConnector, connector])
  // /** used to keep the wallet connected */

  // useEffect(() => {
  //   if (error) {
  //     getErrorMessage(error)
  //   } else {
  //     dispatch({ type: SET_TOAST, payload: { show: false } })
  //   }
  // }, [error])

  // useEffect(() => {
  //   if (account && account !== Cookies.get("account")) {
  //     Cookies.set("account", account)
  //   } else if (!account) {
  //     Cookies.set("account", "")
  //   }
  // }, [account])

  // const getErrorMessage = (error) => {
  //   if (error instanceof NoEthereumProviderError) {
  //     toastHandling({
  //       message:
  //         "No Ethereum browser extension detected. Install MetaMask on desktop or use MetaMask app browser on mobile.",
  //       status: "danger",
  //     })
  //   } else if (error instanceof UnsupportedChainIdError) {
  //     toastHandling({
  //       message:
  //         "You're connected to an unsupported network. Please change to Ethereum Network",
  //       status: "danger",
  //     })
  //   } else if (
  //     error instanceof UserRejectedRequestErrorInjected ||
  //     error instanceof UserRejectedRequestErrorWalletConnect
  //   ) {
  //     toastHandling({
  //       message:
  //         "Please authorize this website to access your Ethereum account.",
  //       status: "danger",
  //     })
  //   } else if (
  //     error?.message &&
  //     error.message.includes("'wallet_requestPermissions' already pending")
  //   ) {
  //     toastHandling({
  //       message: "Metamask already open",
  //       status: "danger",
  //     })
  //   } else {
  //     toastHandling({
  //       message:
  //         "An unknown error occurred. Check the console for more details.",
  //       status: "danger",
  //     })
  //   }
  // }

  // /**
  //   *
  //   * @param {*} connector
  //     import connector from "lib/web3/utils/connecters"
  //     we provide two connector, injected (metamask) and walletconnect,
  //     the default is injected.
  //   * @returns
  //   */
  // const onConnect = async (connector = injected) => {
  //   try {
  //     await activate(connector)
  //   } catch (error) {
  //     return error
  //   }
  // }
  return {
    account: null,
    error: null,
    onConnect: () => {},
    onDisconnect: () => {},
  }
}

export default useConnectWallet
