import {
  SET_USER_DATA,
  SET_ADDRESSES,
  SET_CARTS,
  SET_ORDERS,
  SET_WISHLIST,
  SET_CARTS_ITEM_DETAILS,
} from "./type"

const initialState = {
  user_data: null,
  my_address: [],
  my_carts: [],
  my_orders: [],
  my_wishlist: [],
  my_carts_item_details: [],
}

const reducer = (state = initialState, action) => {
  let { type, payload } = action
  switch (type) {
    case SET_USER_DATA:
      return { ...state, user_data: payload }
    case SET_ADDRESSES:
      return { ...state, my_address: payload }
    case SET_CARTS:
      return { ...state, my_carts: payload }
    case SET_ORDERS:
      return { ...state, my_orders: payload }
    case SET_WISHLIST:
      return { ...state, my_wishlist: payload }
    case SET_CARTS_ITEM_DETAILS:
      return { ...state, my_carts_item_details: payload }
    default:
      return state
  }
}

export default reducer
