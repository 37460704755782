import formatCurrency from "format-currency"
import CurrencyFormatter from "currency-formatter"
import moment from "moment-timezone"
import axios from "axios"
import jwt from "jsonwebtoken"
import nookies from "nookies"
import Cookies from "js-cookie"

//Midtrans required
import { M_CREATE_COMMON_ORDER } from "../graphql"
import { AsyncApollo } from "../functions"
import { capitalize } from "lodash"

export const FormatCurr = (num) => {
  if (num) {
    return num
      .toFixed(0) // always two decimal digits
      .replace(".", ",") // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") // use . as a separator
  }
  return num
}

export const FormatCurrency = (num, currency = "") =>
  formatCurrency(num, {
    format: "%s%v",
    symbol: `${currency} `,
    minFraction: 0,
    maxFraction: 2,
  })

export const FormatCurr2 = (num) => {
  if (Number(num) > 0) {
    return num
      .toFixed(0) // always two decimal digits
      .replace(".", ",") // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") // use . as a separator
  }
  return ""
}

export const FormatCurrency2 = (num, variant, curr) => {
  let currency = Cookies.get(process.env.CURRENCY)

  if (
    String(num).split(" ")[0] === "IDR" ||
    String(num).split(" ")[0] === "USD"
  )
    return num

  if (variant) return formatCurrency(num, { format: "%s%v", symbol: "" })
  else {
    if (curr) {
      return `${curr} ${FormatCurr(num)}`
    } else {
      return `${currency} ${FormatCurr(num)}`
    }
  }
  // return formatCurrency(num, { format: "%s%v", symbol: "", })
  // if (variant) return formatCurrency(num, { format: "%s%v", symbol: "", })
  // return `${currency} ${FormatCurr(num)}`
}

export const ProductLink = (str, id) =>
  "/shop/" + str.toLowerCase().replace("/", "").split(" ").join("-") + "-" + id

export const orderStatus = (detail) => {
  let { order_status } = detail
  let { id } = order_status
  if (id === "1") {
    return {
      id,
      status: "pending",
      alias: "Waiting for Payment",
      text: `Please proceed payment before ${moment(detail?.created_at)
        .add(1, "days")
        .format("LL")} so that we can process your order`,
    }
  } else if (id === "2") {
    return {
      id,
      status: "processing",
      alias: "Processing",
      text: "Your order is currently being processed. Please wait while we’re preparing your order.",
    }
  } else if (id === "3") {
    return {
      id,
      status: "delivery",
      alias: "In Shipping",
      text: "Your order delivered and currently on the way. Please wait within stated delivery time.",
    }
  } else if (id === "4") {
    return {
      id,
      status: "completed",
      alias: "Completed",
      text: "Your order has completed. Don’t forget to leave a review!",
    }
  } else if (id === "5") {
    return {
      id,
      status: "cancelled",
      alias: "Cancelled",
      text: "Your order has been cancelled. If you think this is a mistake, please contact us.",
    }
  } else {
    return {
      id,
      status: "default", // cancel,
      text: "",
    }
  }
}

export const optionsParse = (options, type) => {
  if (type === "categories") {
    let newData = [{ label: "All categories", value: null }]
    options.forEach((item) => {
      newData.push({ label: item.title, value: item.id })
    })
    return newData
  } else {
    let size = [{ label: "All sizes", value: null }]
    let color = [{ label: "All colors", value: null }]
    options.forEach((item) => {
      let { title, values } = item
      if (title === "EU") {
        values.forEach((item2) => {
          size.push({ label: item2.title, value: item2.value })
        })
      }
      if (title === "Color") {
        values.forEach((item2) => {
          color.push({ label: item2.title, value: item2.value })
        })
      }
    })
    return { size, color }
  }
}

// { size, color, price: state.priceOptions, }

export const parseSelect = (query, data) => {
  let sizeOptions = data.size
  let colorOptions = data.color
  let priceOptions = data.price

  let { size, color, price } = query

  let sizeSelect = { label: "All sizes", value: null }
  let colorSelect = { label: "All colors", value: null }
  let priceSelect = { label: "All prices", value: 0 }

  if (size) sizeSelect = sizeOptions.find((item) => item.value === size)
  if (color) colorSelect = colorOptions.find((item) => item.value === color)
  if (price)
    priceSelect = priceOptions.find((item) => item.value === Number(price))

  return {
    sizeSelect,
    colorSelect,
    priceSelect,
  }
}

export const generateToken = (payload) => {
  let token = jwt.sign(payload, process.env.JWT_SECRET)
  return token
}

export const verifyToken = (token) => {
  return jwt.verify(token, process.env.JWT_SECRET)
}

/**
 *
 * @param {*} args //Sample input: ["Color", "Size"]
 * @param {*} options
 * @returns
 */
export const ProductDetailVariantParse = (args = "all", options) => {
  let showVariants = []
  if (args === "all") return options
  else {
  }
}

export const ErrorHandlingPage = (error, notFound) => {
  if (notFound) {
    if (error?.networkError?.statusCode === 500) {
      return { redirect: { destination: "/500", permanet: false } }
    }
    return { notFound: true }
  } else if (error) throw new TypeError(error)
  else return { props: {} }
}

export const CheckGeolocation = async (ctx) => {
  let currency
  if (ctx) {
    currency = nookies.get(ctx)[process.env.CURRENCY]
  } else {
    currency = Cookies.get(process.env.CURRENCY)
  }
  if (currency) return currency
  else if (!ctx) {
    try {
      let { data } = await axios.get(
        `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.ABSTRACT_API_KEY}`
      )
      let { country } = data
      if (country !== "Indonesia") {
        Cookies.set(process.env.CURRENCY, "USD")
        return "USD"
      } else {
        Cookies.set(process.env.CURRENCY, "IDR")
        return "IDR"
      }
    } catch (error) {
      Cookies.set(process.env.CURRENCY, "IDR")
      return "IDR"
    }
  } else {
    return "IDR"
  }
}

export const MidtransTransaction = async (arg, token) => {
  let { address, coupon, deliveryMethod, paymentMethod, lang, currency } = arg

  let midUrl = process.env.NEXT_PUBLIC_MIDTRANS_URL

  try {
    delete address.__typename
    let sendData = {
      address: address,
      shipping_method_id: Number(deliveryMethod.id),
      payment_method: paymentMethod?.title,
      lang,
      currency,
    }
    if (coupon) sendData = { ...sendData, coupon }
    let { data, errors } = await AsyncApollo({
      mutation: M_CREATE_COMMON_ORDER,
      variables: sendData,
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const getInitialOnClient = async () => {
  return { data: "", errors: "" }
}

export const getCategoryId = (str) =>
  str ? str.split("-")[str.split("-").length - 1] : false

export const variantMapper = (variant1, variant2) => {
  let result1, result2
  if (variant1?.includes("size"))
    result1 = `Size ${capitalize(variant1.split("size")[1])}`
  if (variant1?.includes("color"))
    result1 = `Color ${capitalize(variant1.split("color")[1])}`
  if (variant2) {
    if (variant2?.includes("size"))
      result2 = `Size ${capitalize(variant2.split("size")[1])}`
    if (variant2?.includes("color"))
      result2 = `Color ${capitalize(variant2.split("color")[1])}`
  }
  return `${result1}${result2 ? ` - ${result2}` : ""}`
}
export const variantMapper2 = (option_1, option_2) => {
  if (option_1 && option_2) {
    return `${option_1?.title}: ${option_1?.value}, ${option_2?.title} ${option_2?.value}`
  } else if (option_1 && !option_2) {
    return `${option_1.title}: ${option_1?.value}`
  } else {
    return ""
  }
}

export const couponErrorMapper = (code) => {
  switch (code) {
    case "01":
      return "Your code is not eligible. Check the code and try again."
    case "02":
      return "You must be logged in to use this voucher code. Please log in or create an account to proceed."
    case "03":
      return "This code is only valid for first-time purchases."
    case "04":
      return "The code redemption limit has been reached. Try another code."
    case "05":
      return "You must be logged in to use this voucher code. Please log in or create an account to proceed."
    case "06":
      return ""
    case "07":
      return "You have not reached minimum purchase to use this offer."
    case "08":
      return "This promo code cannot be combined with another discount"
    case "09":
      return "This item cannot be purchased using this code."
    case "10":
      return "This voucher code cannot be used at the selected outlet."
    default:
      return "The promo code you entered is not valid"
  }
}

export function transformString(str) {
  // Convert string to lowercase
  if (str) {
    var lowercaseStr = str?.toLowerCase()

    // Replace spaces with underscores
    var transformedStr = lowercaseStr.replace(/\s+/g, "_")

    return transformedStr
  }
  return false
}

//Utils ************************************************************************************************** //
//Utils ************************************************************************************************** //

//States ************************************************************************************************* //
//States ************************************************************************************************* //

//Functions ********************************************************************************************** //
//Functions ********************************************************************************************** //

//React Operations *************************************************************************************** //
//React Operations *************************************************************************************** //
