import React from "react";

//Utils
import { Modal, Button } from "components/anti";

const NotLoginModal = ({ id, hide, handleShoppingBag }) => {
  return (
    <Modal id={id} className="not-login-modal" hide={hide}>
      <h1 className=" text-center mb-4">How do you want to continue?</h1>
    </Modal>
  );
};

export default NotLoginModal;
