import * as Type from "./type"

const initialState = {
  isToast: null,
  toastHeight: false,
  toastState: {
    message: null,
    status: null,
  },
  categories: [],
  collections: [],
  brands: [],
  shoppingBagMenu: {
    initial: false,
    clicked: null,
  },
  countries: null,
  xendit_function: null,
  cityAndSubdistrictList: null,
  guest_checkout: false,
  midtrans: true,
}

const reducer = (state = initialState, action) => {
  let { payload, type } = action
  switch (type) {
    case Type.SET_TOAST:
      let { show, message, status } = payload
      return { ...state, isToast: show, toastState: { message, status } }
    case Type.SET_CATEGORIES:
      return { ...state, categories: payload }
    case Type.SET_COLLECTIONS:
      return { ...state, collections: payload }
    case Type.SET_BRANDS:
      return { ...state, brands: payload }
    case Type.SET_TOAST_HEIGHT:
      return { ...state, toastHeight: payload }
    case Type.SET_IS_DRAWER:
      return { ...state, shoppingBagMenu: payload }
    case Type.SET_XENDIT_FUNCTION:
      return { ...state, xendit_function: payload }
    case Type.SET_COUNTRIES:
      return { ...state, countries: payload }
    case Type.SET_CITY_SUBDISTRICT:
      return { ...state, cityAndSubdistrictList: payload }
    default:
      return state
  }
}

export default reducer
