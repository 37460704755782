/** fill this replacing url with the pinata or etc that store the image */
export const replaceIpfsOrigin = (url) =>
  url?.replace("ipfs://", "https://ipfs.io/ipfs/")

export const truncate = (string, length) => {
  if (string?.length <= length) return string

  let separator = "..."

  let sepLen = separator.length,
    charsToShow = length - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2)

  return (
    string?.substr(0, frontChars) +
    separator +
    string?.substr(string?.length - backChars)
  )
}
