export const UserDataFragment = `
   id
   name
   email
   gender
   dob
   created_at
   updated_at
   email_verified_at
   phone
   provider
`

export const OptionsFragment = `
   key
   title
   values {
      title
      value
   }
`

export const CategoriesFragment = `
   id
   title
   slug
   image
   description
   children {
      id
      slug
      title
      children {
         id
         title
         slug
      }
   }
`

export const AddressFragment = `
   id
   name
   email
   phone
   address
   country
   state
   city
   subdistrict
   postcode
   is_primary
   longitude
   latitude
   geolabel
`

export const ProductFragment = `
   id
   title
   slug
   images
   manage_stock
   price
   sell_price
   is_wishlist
   for_holder
   nft_id
   size_guide
   cut_off_image
   gender
   venue_only
   firstcategory {
      id
      title
      slug
   }
   secondcategory {
      id
      title
      slug
   }
   thirdcategory {
      id
      title
      slug
   }
   skus {
      id
   }
`

export const ProductDetailFragment = `
   id
   title
   slug
   manage_stock
   price
   sell_price
   description
   for_holder
   nft_id
   size_guide
   cut_off_image
   images
   gender
   venue_only
   firstcategory {
      id
      title
      slug
   }
   secondcategory {
      id
      title
      slug
   }
   thirdcategory {
      id
      title
      slug
   }
   skus {
      id
      price
      sell_price
      sku_code
      stock
      option_detail_key1
      option_detail_key2
      image_no
   }
   options {
      title
      values {
        title
        value
      }
   }
   collection {
      ${CategoriesFragment}
   }
`

export const CartFragment = `
   grand_total
   subtotal
   discount_coupon
   weight
   volume
   realweight
   realvolume
   finalweight
   realfinalweight
   point
   shipping_fee
   tax
   free_shipping
   discount_ongkir
   insurance_fee
   free_shipping_value
   must_insurance
   items {
      id
      title
      image
      product_id
      sku_id
      quantity
      price
      stock
      sell_price
      option_1 {
         title
         value
      }
      option_2 {
         title
         value
      }
      collection {
         ${CategoriesFragment}
      }
      manage_stock
   }
   code
   status
   message
`
//code

export const OrderFragment = `
   id
   order_no
   total_amount
   shipping_fee
   discount_ongkir
   insurance
   tax
   unique_code
   payment_service_fee
   total_discount
   currency
   exchange_rate
   final_amount
   charge_service_fee
   pay_with_point
   billing {
      id
      name
      phone
      email
      address
      country
      state
      city
      subdistrict
      postcode
   }
   shipping {
      id
      name
      phone
      email
      address
      country
      state
      city
      subdistrict
      postcode
      shipping_method
      tracking_number
   }
   order_status {
      id
      title
   }
   details {
      product_id
      title
      image
      sku_code
      price
      discount
      discount_coupon
      quantity
      notes
      manage_stock
      option_1 {
         title
         value
      }
      option_2 {
         title
         value
      }
      collection {
         id
         title
         slug
       }
   }
   payment_method
   tracking_logs {
      created_at
      message
   }
   created_at
   updated_at
   status
   message
   payment_info {
      status
      account_number
      checkout_url
      snap_token
   }
`

export const PaginationFragment = `
   count
   current_page
   total_data
   last_page
`

export const CountryFragment = `
    id
    name
    code
`

export const MidtransOrderFragment = `
   id
   order_no
   total_amount
   shipping_fee
   discount_ongkir
   insurance
   tax
   unique_code
   payment_service_fee
   currency
   exchange_rate
   final_amount
   charge_service_fee
   pay_with_point
   billing {
      id
      name
      phone
      email
      address
      country
      state
      city
      subdistrict
      postcode
   }
   shipping {
      id
      name
      phone
      email
      address
      country
      state
      city
      subdistrict
      postcode
      shipping_method
      tracking_number
   }
   order_status {
      id
      title
   }
   details {
      product_id
      title
      image
      sku_code
      price
      discount
      discount_coupon
      quantity
      notes
      option_1 {
         title
         value
      }
      option_2 {
         title
         value
      }
   }
   payment_method
   tracking_logs {
      created_at
      message
   }
   created_at
   updated_at
   status
   message
   payment_info {
      status
      account_number
      checkout_url
      snap_token
   }
`

export const BrandsFragment = `
   id
   title
   slug
   is_featured
   image
`
