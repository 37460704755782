import { Link } from "components/anti/link/link"
import { NewsLetter } from "components/anti/newsletter/newsletter"

import arrow from "assets/img/common/arrow-ne-white.svg"
import logo from "assets/img/common/logo_main-dark.png"

const Footer2 = ({
  query,
  menuLinks,
  socialList,
  paymentList,
  handleSubmit,
  socialDataLayer,
}) => {
  const date = new Date()
  return (
    <footer className="footer-2">
      <section className="footer-header">
        <div className="row row-newsletter">
          <div className="container">
            <div className="row newsletter-content py-3">
              <div className="col-md-6">
                <h2 className="title">Subscribe NewsLetter</h2>
              </div>
              <div className="col-md-6">
                <NewsLetter fn={handleSubmit} query={query} />
              </div>
            </div>
          </div>
        </div>
        <div className="row-payment container">
          <div className="row">
            <div className="col-xl-6 payment-content">
              <p className="title">Accepted Payment</p>
              <ul className="payment-list">
                {paymentList.map((item, i) => (
                  <li className="payment-item" key={`payment-${i}`}>
                    <img src={item} alt="payment" className="img-fluid" />
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-xl-6 contact-content">
              <ul className="list-unstyled">
                <li>
                  E.{" "}
                  <a
                    href="mailto:info@heymonstore.com"
                    target="_blank"
                    className="hover-underline"
                  >
                    info@heymonstore.com
                  </a>{" "}
                </li>
                <li>
                  WA.
                  <a
                    href="https://wa.me/62818131514"
                    target="_blank"
                    className="hover-underline"
                  >
                    0818 13 15 14
                  </a>
                </li>
                <li>
                  IG.
                  <a
                    href="https://instagram.com/monstore"
                    target="_blank"
                    className="hover-underline"
                  >
                    @monstore
                  </a>
                </li>
              </ul>
              {/* <p className="title">FOLLOW US</p>
              <div className="social-list">
                {socialList.map((item, i) => (
                  <Link
                    target="_blank"
                    to={item.link}
                    className="social-item"
                    onClick={() => socialDataLayer(item)}
                    key={i}
                  >
                    <i className={`aib ${item.icon}`} />
                  </Link>
                ))}
              </div> */}
            </div>
          </div>
          <hr />
        </div>
      </section>
      <section className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-copyright mt-3 mt-md-0">
              <div className="site">
                © {date.getFullYear()} Monstore. Site by{" "}
                <a href="https://antikode.com/" target={"_blank"}>
                  Antikode
                </a>
              </div>
            </div>
            <div className="col-md-8 col-menu">
              <div className="row w-100 justify-content-end">
                {menuLinks.map((item, i) => (
                  <div
                    className="col-12 col-md-auto mb-3 mb-md-0"
                    key={`menu-item-${i}`}
                  >
                    <Link className="h5 menu-item" to={item.link} key={i}>
                      {item.title}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer2
