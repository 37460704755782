import { useState } from "react"

//Utils
import { search_link } from "components/links"
import { useRouter } from "next/router"

//Components
import { Input } from "components/anti"

const SearchMenu = ({
  handleSearch,
  navExpand,
  variant,
  handleSearchMobile,
}) => {
  const router = useRouter()
  const [searchKey, setSearchKey] = useState("")

  const navigateSearch = (e) => {
    if (e.key === "Enter") {
      handleSearch()
      router.push({
        pathname: search_link,
        query: { keyword: searchKey },
      })
      // router.push(`${search}?keyword=${searchKey}`)
    }
  }
  return (
    <>
      {/* DESKTOP  */}
      <div className="search-menu-desktop hide">
        <div className="container d-block">
          <Input
            className="w-100 search-input"
            variant="basic"
            id="search"
            name="search"
            placeholder="Search our product here"
            iconLeft
            iconRight
            onChange={(e) => setSearchKey(e.target.value)}
            onKeyPress={navigateSearch}
          >
            <div className="btn btn-close" onClick={handleSearch} />
          </Input>
        </div>
      </div>

      {/* MOBILE  */}
      <div
        className={`search-menu-mobile mobile-menu d-${navExpand}-none ${variant}`}
      >
        <div className="mobile-menu-content py-0">
          <div className="container d-block">
            <Input
              className="search-input"
              variant="underline"
              id="search"
              name="search"
              placeholder="Seach our product here"
              iconLeft
              iconRight
              onChange={(e) => setSearchKey(e.target.value)}
              onKeyPress={navigateSearch}
            >
              <div className="btn btn-close" onClick={handleSearchMobile} />
            </Input>
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchMenu
