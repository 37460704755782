import React from "react"

//Componentgs
import { Modal, Button } from "components/anti"

//Utils
import useConnectWallet from "lib/web3/hooks/useConnectWallet"
import { truncate } from "lib/web3/utils/helpers"

const ModalDisconnectWallet = ({ show, hide, id }) => {
  const { account, onDisconnect } = useConnectWallet()
  return (
    <Modal
      id={id}
      hide={hide}
      isShowing={show}
      className="centered modal-md"
      contentClassName="modal-connect-wallet"
    >
      <h2 className="title pb-3">Wallet Connected</h2>
      <p className="mt-3">
        Your connected wallet address: <strong>{truncate(account, 30)}</strong>
      </p>
      <Button
        loader
        onClick={() => {
          onDisconnect()
          hide()
        }}
        className={`w-100 mt-3`}
      >
        Disconnect
      </Button>
    </Modal>
  )
}

export default ModalDisconnectWallet
