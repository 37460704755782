import {
   M_LOGIN,
   M_REGISTER,
   M_ADD_CART
} from 'lib/graphql/mutation';

//Utils
import { initializeApollo } from 'lib/apollo';
import Cookies from 'js-cookie';

const FecthingClient = (token) => {
   if (!token) {
      let guestToken = Cookies.get(process.env.GUEST_TOKEN);
      let accessToken = Cookies.get(process.env.ACCESS_TOKEN);
      if (accessToken) return { client: initializeApollo(accessToken), token };
      else return { client: initializeApollo(guestToken), token }
   } else return { client: initializeApollo(token), token }
}

/**
 * !+++++++++++++++++++++ LOGIN +++++++++++++++++++++++++
 * @param {*} values
   sample values: { username: String!, password: String! }
 * @param {*} token
 */
export const F_M_LOGIN = async (values, token) => {
   let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`);
   let client = initializeApollo(guestToken);
   try {
      let { data, errors } = await client.mutate({ mutation: M_LOGIN, variables: { ...values } });
      if (data) return { data: data.login }
      else return { errors }
   } catch (error) {
      return { errors: error }
   }
};

/**
 * !++++++++++++++++++ REGISTER +++++++++++++++++++++++++
 * @param {*} values
   Sample values: {
         name: String!,
         email: String!,
         dob: String! <sample: "1970-01-01">,
         gender: String!,
         is_subscribe: Int!,
         phone: String!
         password: String!,
         password_confirmation: String!
   }
 * @param {*} token
 */
export const F_M_REGISTER = async (values, token) => {
   let client = initializeApollo(`${process.env.GUEST_TOKEN}`);
   try {
      let { data, errors } = await client.mutate({ mutation: M_REGISTER, variables: { ...values }, errorPolicy: "all" });
      if (data) return { data: data?.register };
      else return { errors }
   } catch (error) {
      return { errors: error }
   }
};


export const F_M_ADD_TO_CART = async (values, token) => {
   let { client } = FecthingClient();
   try {
      let { data, errors } = await client.mutate({ mutation: M_ADD_CART, variables: { ...values } });
      if (data) return { data };
      else return { errors }
   } catch (error) {
      return { errors: error }
   }
}