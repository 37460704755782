import { gql } from "@apollo/client"

//Fragment
import {
  CartFragment,
  AddressFragment,
  OrderFragment,
} from "lib/graphql/fragment"

//!Login User
export const M_LOGIN = gql`
  mutation LOGIN($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      access_token
      refresh_token
      expires_in
      status
      message
      user {
        name
        email
      }
    }
  }
`

//! FORGOT PASSWORD
export const M_FORGOT_PASSWORD = gql`
  mutation FORGOT_PASSWORD($email: String!) {
    forgotPassword(input: { email: $email }) {
      status
      message
    }
  }
`

export const M_UPDATE_FORGOTTEN_PASSWORD = gql`
  mutation FORGOT_UPDATE(
    $email: String!
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    updateForgottenPassword(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`

//! UPDATE USER
export const M_UPDATE_USER = gql`
  mutation EDIT_PROFILE(
    $name: String!
    $dob: String!
    $gender: String!
    $phone: String
  ) {
    updateUser(
      input: { name: $name, dob: $dob, gender: $gender, phone: $phone }
    ) {
      status
      message
    }
  }
`

//!Register User
export const M_REGISTER = gql`
  mutation register(
    $name: String!
    $email: String!
    $gender: String!
    $dob: Date!
    $password: String!
    $password_confirmation: String!
    $is_subscribe: Int!
    $phone: String
    $provider: String
    $provider_id: String
    $token: String
  ) {
    register(
      input: {
        name: $name
        email: $email
        gender: $gender
        password: $password
        password_confirmation: $password_confirmation
        dob: $dob
        is_subscribe: $is_subscribe
        phone: $phone
        provider: $provider
        provider_id: $provider_id
        token: $token
      }
    ) {
      tokens {
        access_token
        refresh_token
        expires_in
        user {
          id
          name
          email
          created_at
          updated_at
        }
      }
      status
      message
    }
  }
`

//!Refresh Token
export const M_REFRESH_TOKEN = gql`
  mutation REFRESH_TOKEN($refresh_token: String) {
    refreshToken(input: { refresh_token: $refresh_token }) {
      access_token
      refresh_token
      expires_in
      token_type
    }
  }
`

//!Add Cart
export const M_ADD_CART = gql`
mutation ADD_CART(
  $sku_id: ID!
  $quantity: Int!
  $currency: String
) {
  addToCart(sku_id: $sku_id, language: "en", quantity: $quantity, currency: $currency) {
    ${CartFragment}
  }
}
`

//!DELETE ADDRESS
export const M_DELETE_ADDRESS = gql`
  mutation deleteAddress($id: ID!) {
    deleteAddress(id: $id) {
      status
    }
  }
`

//!UPDATE PASSWORD
export const M_UPDATE_PASSWORD = gql`
  mutation UPDATE_PASSWORD(
    $old_password: String!
    $password: String!
    $password_confirmation: String!
  ) {
    updatePassword(
      input: {
        old_password: $old_password
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`

//!Notify
export const M_NOTIFY_ME = gql`
  mutation M_NOTIFY($sku_id: ID!, $email: String!) {
    addToNotify(sku_id: $sku_id, email: $email) {
      status
      message
    }
  }
`

//!CREATE UPDATE ADDRESS
export const M_CREATE_UPDATE_ADDRESS = gql`
  mutation createUpdateAddress(
    $address_id: Int
    $name: String!
    $email: String!
    $phone: String!
    $address: String!
    $country: String!
    $state: String!
    $city: String!
    $subdistrict: String!
    $postcode: String!
    $is_primary: Int
    $longitude: String
    $latitude: String
    $geolabel: String
  ) {
    createUpdateAddress(
      input: {
        address_id: $address_id
        name: $name
        email: $email
        phone: $phone
        address: $address
        country: $country
        state: $state
        city: $city
        subdistrict: $subdistrict
        postcode: $postcode
        is_primary: $is_primary
        longitude: $longitude
        latitude: $latitude
        geolabel: $geolabel
      }
    ) {
      ${AddressFragment}
    }
  }
`

//!CREATE ORDER
export const M_CREATE_ORDER = gql`
  mutation CREATE_ORDER(
    $coupon: String
    $use_point: Int
    $address: AddressInput!
    $shipping_method_id: Int!
    $payment_method: String!
    $source: OrderSource
    $ref: String
    $ewallet_phone: String
    $token_id: String!
    $authentication_id: String!
    $cvv: String!
  ) {
    createOrder(
      language: "en"
      coupon: $coupon
      shipping: $address
      billing: $address
      payment_method: $payment_method
      source: $source
      ref: $ref
      ewallet_phone: $ewallet_phone
      credit_card: {
        token_id: $token_id
        authentication_id: $authentication_id
        cvv: $cvv
      }
      use_point: $use_point
      shipping_method: { shipping_method_id: $shipping_method_id }
    ) {
        ${OrderFragment}
    }
  }
`

export const M_CREATE_COMMON_ORDER = gql`
  mutation CREATE_COMMON_ORDER(
    $currency: String
    $coupon: String
    $use_point: Int
    $address: AddressInput!
    $shipping_method_id: Int!
    $payment_method: String!
    $source: OrderSource
    $ref: String
    $ewallet_phone: String
  ) {
    createOrder(
      language: "en"
      coupon: $coupon
      shipping: $address
      billing: $address
      payment_method: $payment_method
      currency: $currency
      source: $source
      ref: $ref
      ewallet_phone: $ewallet_phone
      use_point: $use_point
      shipping_method: { shipping_method_id: $shipping_method_id }
    ) {
      ${OrderFragment}
    }
  }
`

//!RETRY_PAYMENT
export const M_REPAYMENT = gql`
  mutation RETRY_PAYMENT($order_id: Int!, $payment_method: String!) {
  repayment(
    language: "en",
    order_id: $order_id,
    payment_method: $payment_method,
  ) {
    ${OrderFragment}
  }
}
`

//!UPDATE CARTS
export const M_UPDATE_CARTS = gql`
  mutation UPDATE_CART($id: ID!, $quantity: Int!, $currency: String) {
    updateCart(id: $id, language: "en", quantity: $quantity, currency: $currency) {
      ${CartFragment}
    }
  }
`

//!Delete Carts
export const M_DELETE_CARTS_ITEM = gql`
  mutation DELETE_CARTS_ITEM($id: ID!, $currency: String) {
    deleteCart(id: $id, language: "en", currency: $currency) {
      ${CartFragment}
    }
  }
`

//!Contact Us
export const M_CONTACT_US = gql`
  mutation CONTACT(
    $name: String!
    $email: String!
    $message: String!
    $topic: String!
  ) {
    contactForm(
      input: { name: $name, email: $email, message: $message, topic: $topic }
    ) {
      status
      message
    }
  }
`

//!VerifyEmail
export const M_VERIFY_EMAIL = gql`
  mutation VERIFY_EMAIl($id: Int!, $hash: String!) {
    verifyEmail(input: { id: $id, hash: $hash }) {
      access_token
      refresh_token
      expires_in
      token_type
    }
  }
`
export const M_RESEND_VERIFY_EMAIL = gql`
  mutation RESEND_VERIFY_EMAIl {
    verify {
      status
      message
    }
  }
`

export const M_SUBSCRIBE = gql`
  mutation subscribe($email: String!) {
    subscribe(email: $email) {
      status
      message
    }
  }
`

export const M_SOCIAL_LOGIN = gql`
  mutation SOCIAL_LOGIN($token: String!, $provider: String!) {
    socialLogin(input: { token: $token, provider: $provider }) {
      access_token
      refresh_token
      expires_in
      token_type
    }
  }
`
