
export const F_NAVBAR_MOBILE_MENU = (mobileMenu) => {
   const navToggler = document.querySelector(".nav-toggler")
   const navMobileMenu = document.querySelector(".mobile-menu")
   const menuBg = document.querySelector(".first-menu")
   const html = document.querySelector("html")
   const body = document.querySelector("body")
   html.classList.remove("no-scroll")
   // prettier-ignore
   if (mobileMenu.clicked === false) {
      // Toggle inactive
      navToggler.classList.add("inactive")
      navMobileMenu.classList.remove("show")
      navMobileMenu.classList.add("hide")
      body.classList.remove("no-scroll")
      html.classList.remove("no-scroll")
      setTimeout(() => {
         menuBg.classList.remove("show")
         menuBg.classList.add("hide")
      }, 1500);
   } else if (mobileMenu.clicked === true || (mobileMenu.clicked === true && mobileMenu.initial === null)) {
      // Toggle active
      navToggler.classList.remove("inactive")
      navMobileMenu.classList.add("show")
      menuBg.classList.add("show")
      body.classList.add("no-scroll")
      html.classList.add("no-scroll")
   }
};

export const F_NAVBAR_MOBILE_SHOP_MENU = (mobileShopMenu) => {
   const navMobileShopMenu = document.querySelector(".mobile-shop-menu")
   const menuBg = document.querySelector(".second-menu")
   // prettier-ignore
   if (mobileShopMenu.clicked === false) {
      // Toggle inactive
      navMobileShopMenu.classList.remove("show")
      navMobileShopMenu.classList.add("hide")
      setTimeout(() => {
         menuBg.classList.remove("show")
         menuBg.classList.add("hide")
      }, 500);
   } else if (mobileShopMenu.clicked === true || (mobileShopMenu.clicked === true && mobileShopMenu.initial === null)) {
      // Toggle active
      menuBg.classList.add("show")
      navMobileShopMenu.classList.add("show")
   };
};

export const F_NAVBAR_MOBILE_ACCOUNT_MENU = (mobileAccountMenu) => {
   const navMobileAccountMenu = document.querySelector(".mobile-account-menu")
   const menuBg = document.querySelector(".second-menu")
   // prettier-ignore
   if (mobileAccountMenu.clicked === false) {
      // Toggle inactive
      navMobileAccountMenu.classList.remove("show")
      navMobileAccountMenu.classList.add("hide")
      setTimeout(() => {
         menuBg.classList.remove("show")
         menuBg.classList.add("hide")
      }, 500);
   } else if (mobileAccountMenu.clicked === true || (mobileAccountMenu.clicked === true && mobileAccountMenu.initial === null)) {
      // Toggle active
      menuBg.classList.add("show")
      navMobileAccountMenu.classList.add("show")
   }
};

export const F_NAVBAR_SEARCH = (search) => {
   // const searchMenu = document.querySelector(".search-menu")
   const searchMenuDesktop = document.querySelector(".search-menu-desktop")
   // const html = document.querySelector("html")
   // const body = document.querySelector("body")
   // const navbarMain = document.querySelector(".navbar-main")
   // prettier-ignore
   if (search.clicked === false) {
      // Toggle inactive
      // searchMenu.classList.remove("show")
      // searchMenu.classList.add("hide")
      searchMenuDesktop.classList.remove("show")
      searchMenuDesktop.classList.add("hide")
      // body.classList.remove("no-scroll")
      // html.classList.remove("no-scroll")
      // navbarMain.classList.remove("search-show")
      // navbarMain.classList.add("search-hide")
   } else if (search.clicked === true || (search.clicked === true && search.Mobileinitial === null)) {
      // Toggle active
      // searchMenu.classList.add("show")
      searchMenuDesktop.classList.add("show")
      // body.classList.add("no-scroll")
      // html.classList.add("no-scroll")
      // navbarMain.classList.add("search-show")
   }
};

export const F_NAVBAR_SEARCH_MOBILE = (searchMobile) => {
   // const searchMenu = document.querySelector(".search-menu")
   const searchMenuCustomMobile = document.querySelector(".search-menu-mobile")
   const html = document.querySelector("html")
   const body = document.querySelector("body")
   // const navbarMain = document.querySelector(".navbar-main")
   // prettier-ignore
   if (searchMobile.clicked === false) {
      // Toggle inactive
      // searchMenu.classList.remove("show")
      // searchMenu.classList.add("hide")
      searchMenuCustomMobile.classList.remove("show")
      searchMenuCustomMobile.classList.add("hide")
      body.classList.remove("no-scroll")
      html.classList.remove("no-scroll")
      // navbarMain.classList.remove("search-show")
      // navbarMain.classList.add("search-hide")
   } else if (searchMobile.clicked === true || (searchMobile.clicked === true && search.Mobileinitial === null)) {
      // Toggle active
      // searchMenu.classList.add("show")
      searchMenuCustomMobile.classList.add("show")
      body.classList.add("no-scroll")
      html.classList.add("no-scroll")
      // navbarMain.classList.add("search-show")
   }
};

export const F_NAVBAR_BAG_MENU = (shoppingBagMenu) => {
   // const navToggler = document.querySelector(".nav-toggler")
   const navShoppingBagMenu = document.querySelector(".bag-menu")
   const menuBg = document.querySelector(".first-menu")
   const html = document.querySelector("html")
   const body = document.querySelector("body")
   // prettier-ignore
   if (shoppingBagMenu.clicked === false) {
      // Toggle inactive
      // navToggler.classList.add("inactive")
      navShoppingBagMenu.classList.remove("show")
      navShoppingBagMenu.classList.add("hide")
      menuBg.classList.remove("show")
      menuBg.classList.add("hide")
      body.classList.remove("no-scroll")
      html.classList.remove("no-scroll")
   } else if (shoppingBagMenu.clicked === true || (shoppingBagMenu.clicked === true && shoppingBagMenu.initial === null)) {
      // Toggle active
      // navToggler.classList.remove("inactive")
      navShoppingBagMenu.classList.add("show")
      menuBg.classList.add("show")
      body.classList.add("no-scroll")
      html.classList.add("no-scroll")
   }
};
