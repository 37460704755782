export const ENUM = {
  IMAGE_RATIO: "r-10-13",
  ORDER_STATUS: [
    {
      id: "1",
      status: "pending",
      text: "Waiting for Payment",
      desc: "You have not complete your payment yet. Please submit your payment immediately",
    },
    {
      id: "2",
      status: "paid",
      text: "Payment Verified",
      desc: "We have accepted your payment and your package will be shipped soon.",
    },
    {
      id: "3",
      status: "delivery",
      text: "Under Shipment",
      desc: "Your order is currently being processed. Please wait while we’re preparing your order.",
    },
    {
      id: "4",
      status: "completed",
      text: "Completed",
      desc: "Your order has been completed. Thank you for shopping at Monstore",
    },
    {
      id: "5",
      status: "cancelled",
      text: "Cancelled",
      desc: "Your order has been canceled. If you think this a mistake , please contact us",
    },
    {
      id: "6",
      status: "returned",
      text: "Returned",
      desc: "Your order has been returned. If you think this a mistake , please contact us",
    },
  ],
  PASSWORD_REGEX: /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
}
