export const login_link = "/login";
export const update_password_link = "/login/update-password";
export const register_link = "/register";
export const shop_link = "/shop";
export const my_order_link = "/account/orders";
export const my_account_link = "/account/account-settings";
export const my_address_link = "/account/address";
export const checkout_link = "/checkout";
export const checkout_login_link = "/checkout/login";
export const checkout_register_link = "/checkout/register";
export const product_link = "/product";
export const search_link = "/search";
export const privacy_policy_link = "/privacy-policy";
export const terms_conditions_link = "/terms-conditions";
export const faq_link = "/faq"
export const contact_us_link = "/contact"
export const category_link = "/category"
export const journal_link = "/journal"