import React from "react"

//Utils
import { checkout_link, checkout_login_link } from "components/links"
import {
  FormatCurrency,
  transformString,
  variantMapper2,
} from "lib/utils/general-utils"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"

//Components
import { Button } from "components/anti"
import NotLoginModal from "components/modal/not-login-modal"
import { parseCookies } from "nookies"
import BagItem from "./bag-item"

import imgPackage from "assets/img/common/logo-package.png"
import Cookies from "js-cookie"
import { AsyncApollo, Q_BRAND_COLLECTION } from "lib/functions"
import { dataLayerEvent } from "lib/functions/datalayer-v2"
import { SET_CARTS_ITEM_DETAILS } from "lib/redux"

const BagMenu = ({ variant, handleShoppingBag }) => {
  //Utils ******************************************************************** //
  const modalGuest = "modal-guest-checkout"
  const Router = useRouter()
  const currency = parseCookies()?.currency
  //Utils ******************************************************************** //

  //States ******************************************************************* //
  const { my_carts, user_data, my_carts_item_details } = useSelector(
    (state) => state.auth
  )
  const [state, setState] = React.useState({
    showNotLoginModal: null,
    btnLoading: false,
    cartItems: null,
    shakeTsel: false,
    isPreOrder: false,
  })
  const tselProduct = my_carts?.items?.filter(
    (item) => item.collection?.slug === "telkomsel-poin"
  )
  const validationTsel = tselProduct?.length > 1 && tselProduct[0]?.quantity > 1
  //States ******************************************************************* //

  //Functions **************************************************************** //
  const handleNavigateCheckout = () => {
    if (!validationTsel) {
      setState((prev) => ({ ...prev, btnLoading: true }))
      // DataLayer
      dataLayerEvent.beginCheckout({
        ...my_carts,
        currency: Cookies.get(process.env.CURRENCY),
        items: my_carts_item_details,
      })
      if (user_data) {
        window.location.href = checkout_link
      } else {
        Router.push(checkout_login_link)
      }
    } else {
      setState((prev) => ({ ...prev, shakeTsel: true }))
      setTimeout(
        () => setState((prev) => ({ ...prev, shakeTsel: false })),
        1500
      )
      const parent = document.getElementById("bag-menu-content-top")
      parent.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }
  //Functions **************************************************************** //
  //React Operations *************************************************************************************** //
  React.useEffect(() => {
    if (my_carts) {
      let items = my_carts?.items
      let hasPreOrder = items?.find((item) => item?.manage_stock === 0)
      setState((prev) => ({
        ...prev,
        cartItems: Router.query["checkout-validation-tsel"]
          ? [
              ...items?.filter(
                (item) => item?.collection?.slug === "telkomsel-poin"
              ),
              ...items?.filter(
                (item) => item?.collection?.slug !== "telkomsel-poin"
              ),
            ]
          : items,
        isPreOrder: hasPreOrder,
      }))
    }
  }, [my_carts])
  //React Operations *************************************************************************************** //
  return (
    <div className={`bag-menu mobile-menu ${variant}`}>
      <NotLoginModal
        id={modalGuest}
        show={state.showNotLoginModal}
        hide={() => setState((prev) => ({ ...prev, showNotLoginModal: null }))}
        handleShoppingBag={handleShoppingBag}
      />
      <div className="bag-menu-content">
        <div className="heading">
          <div className="container">
            <div>
              <h3>
                <i className="air ai-shopping-bag mr-3" />
                BAG{" "}
                {/* <small>{my_carts ? my_carts?.items?.length : 0}</small>{" "} */}
              </h3>
              <div className="btn btn-close" onClick={handleShoppingBag}>
                <i className="ail ai-times" />
              </div>
            </div>
          </div>
        </div>
        <div className="bag-menu-content-top" id="bag-menu-content-top">
          <div className="container">
            {state.isPreOrder ? (
              <div className="bag-menu-preorder">
                <div>
                  <img src={imgPackage} className="img-package" />
                </div>
                <div>
                  <p className="mb-0">
                    <strong>Pre-order item included. </strong>
                    We'll secure the items for you to pick up at ticket exchange
                    / #DWP24 event, or have them delivered after #DWP24 event
                    ended.
                  </p>
                  {/* <p className="mb-0">
                    <strong>Pre-order item included. </strong>
                    Pre-order made until 4 Dec 2023 will be available for pick
                    up at ticket exchange / DWP event in Bali (skipping queue).
                  </p> */}
                </div>
              </div>
            ) : null}
            {state?.cartItems?.length && validationTsel ? (
              <span className={`tsel-error ${state?.shakeTsel ? "shake" : ""}`}>
                <p>
                  <b>Whoops!</b>
                </p>
                <p>
                  You can buy max 1 Telkomsel Point item in one checkout. Please
                  remove other item to continue
                </p>
              </span>
            ) : null}
            {state?.cartItems?.length ? (
              state?.cartItems?.map((item, i) => (
                <BagItem
                  key={i}
                  product={item}
                  currency={currency}
                  setState={setState}
                  detailProduct={my_carts_item_details[i]}
                />
              ))
            ) : (
              <div className="empty-state">
                <h5 className="mb-3">There are no items in your bag</h5>
                <Button link="/" onClick={() => handleShoppingBag()}>
                  Browse All Products
                </Button>
              </div>
            )}
          </div>
        </div>

        {state.cartItems && state.cartItems.length > 0 && (
          <div className="bag-menu-content-bottom">
            <div className="container">
              <div className="content-bottom-left">
                <p>Subtotal</p>
                <small>Have promo code? Use in check out.</small>
              </div>
              <div className="content-bottom-right">
                <p>
                  {my_carts
                    ? FormatCurrency(my_carts?.subtotal, currency)
                    : "0"}
                </p>
              </div>
              <Button
                variant="primary"
                loader
                className={`w-100 ${state.btnLoading ? "loading" : ""} ${
                  validationTsel ? "disabled" : ""
                }`}
                disabled={!my_carts}
                onClick={handleNavigateCheckout}
              >
                Proceed to Checkout
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BagMenu
