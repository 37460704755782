import React from 'react'

import { SET_TOAST } from 'lib/redux/type';
import { initializeStore } from 'lib/redux'

// //Define dispatch
// const reduxStore = initializeStore()
// const { dispatch } = reduxStore

//!Wording
const noStockWording = ""
const noSelectSizeWording = "Please select size first"
const resendWording = "Link already sent your email"
const successUpdatePassword = "Your password already updated"
const invalidOldPassword = "Change password failed. Make sure you check your password."
const invalidPasswordWording = "Invalid email / password"
const verifiedUserOnly = "Please login first as user"
const resendEmailWording = "Link already sent to your email"
const manipulatingWording = "Sorry, your payment failed. We are unable to verified your transaction."
const suspendedMsg = "Invalid email / password. You've tried to log in too many times. For security, your account is suspended for 5 minutes or contact us for further guidance"
const emailAlreadyRegisterWording = "Email already taken!"
const generalErrorWording = "Oops, Something wrong..."

export const toastHandling = (arg, fn) => {

   let { message, status, type, } = arg;

   const reduxStore = initializeStore();
   const { dispatch } = reduxStore
   if (type === "general_error")
      return dispatch({ type: SET_TOAST, payload: { show: true, message: generalErrorWording, status: "danger" } });
   if (type === "update_password")
      return dispatch({ type: SET_TOAST, payload: { show: true, message: successUpdatePassword, status: "success" } });
   if (type === "invalid_password")
      return dispatch({ type: SET_TOAST, payload: { show: true, message: invalidPasswordWording, status: "danger" } })
   if (type === "verified_user")
      return dispatch({ type: SET_TOAST, payload: { show: true, message: verifiedUserOnly, status: "danger" } })
   if (type === "suspended")
      return dispatch({ type: SET_TOAST, payload: { show: true, message: suspendedMsg, status: "danger" } })
   if (type === "resend_email")
      return dispatch({ type: SET_TOAST, payload: { show: true, message: resendEmailWording, status: "success" } })
   if (type === "manipulating price")
      return dispatch({ type: SET_TOAST, payload: { show: true, message: manipulatingWording, status: "danger" } })
   if (type === "invalid_email")
      return dispatch({ type: SET_TOAST, payload: { show: true, message: emailAlreadyRegisterWording, status: "danger" } })
   if (type === "invalid_update_password")
      return dispatch({ type: SET_TOAST, payload: { show: true, message: invalidOldPassword, status: "danger" } })
   else
      return dispatch({ type: SET_TOAST, payload: { show: true, message, status } });


};

export const ErrorHandling = () => {

};