import { Link } from "components/anti/link/link"
import { NewsLetter } from "components/anti/newsletter/newsletter"

import arrow from "assets/img/common/arrow-ne-white.svg"
import logo from "assets/img/common/logo_main-light.png"

const Footer1 = ({
    menuLinks,
    socialList,
    paymentList,
    socialDataLayer,
    othersLinkDataLayer,
}) => {
    return (
        <footer className="footer-1">
            <section className="footer-header py-4">
                <div className="container">
                    <div className="footer-header-content">
                        <p className="title">
                            ACCEPTED PAYMENTS
                        </p>
                        <ul className="payment-list">
                            {paymentList.map((item, i) => (
                                <li className="payment-item" key={i}>
                                    <img src={item} alt="payment" className="img-fluid" />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
            <section className="footer-main py-5">
                <div className="container">
                    <div className="row row-4">
                        <div className="col-lg-4">
                            <img src={logo} className="img-fluid logo-main" alt="logo" />
                            <div className="social-link">
                                {socialList.map((item, i) => (
                                    <Link
                                        to={item.link}
                                        className="social-item"
                                        onClick={() => socialDataLayer(item)}
                                        key={i}
                                    >
                                        <i className={`aib ${item.icon}`} />
                                    </Link>
                                ))}
                            </div>
                            <p>Made with Antikode</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="row row-4">
                                {menuLinks.map((menu, i) => (
                                    <div className="col-12">
                                        <Link
                                            className="menu-item"
                                            key={i}
                                            to={menu.link}
                                            onClick={() => othersLinkDataLayer(menu.title)}
                                        >
                                            {menu.title}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="text-uppercase small-text">Get updates about new products and discounts</p>

                            {/* NEWSLETTER ********************** */}
                            <NewsLetter />
                            {/* NEWSLETTER ********************** */}
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )
}

export default Footer1