import React from "react"
import { Button } from "components/anti/buttons/buttons"
import { shop_link } from "components/links"
import { useRouter } from "next/router"
import { useSelector } from "react-redux"

const MegaMenu = ({ showMegaMenu, setShowMegaMenu }) => {
  const Router = useRouter()
  const { brands } = useSelector((state) => state.general)
  const handleExit = () => {
    setShowMegaMenu((prev) => ({ ...prev, show: false }))
  }

  return (
    <>
      <div className={`backdrop megamenu ${showMegaMenu?.show ? "show" : ""}`}></div>
      <div
        className={`desktop-megamenu ${showMegaMenu?.show ? "active" : "hide"}`}
        onMouseOver={() =>
          setShowMegaMenu((previous) => ({ ...previous, show: true }))
        }
        onMouseLeave={() =>
          setShowMegaMenu((previous) => ({ ...previous, show: false }))
        }
      >
        <div className="container">
          <div className="megamenu-nav-wrapper">
            {showMegaMenu?.data?.children
              ?.filter((item) => item.title !== "Special")
              ?.map((item) => (
                <>
                  {item.title !== "All" ? (
                    <>
                      <div className={`megamenu-nav`} key={`${item.id}-desktop`}>
                        {item.title !== "All" ? (
                          <>
                            <h5
                              onClick={() => {
                                Router.push(
                                  `${shop_link}/${showMegaMenu?.data?.slug}-${showMegaMenu?.data?.id}/${item.slug}-${item.id}`
                                )
                                handleExit()
                              }}
                              className={`item`}
                            >
                              All {item.title}
                            </h5>
                          </>
                        ) : null}
                        <ul className="nav-item-list">
                          {item.children?.map((item2) => (
                            <>
                              {item2.title !== "All" ? (
                                <li
                                  key={`${item2.id}-desktop`}
                                  className="nav-item"
                                  onClick={handleExit}
                                >
                                  <Button
                                    variant="link"
                                    link={
                                      `${shop_link}/${showMegaMenu?.data?.slug}-${showMegaMenu?.data?.id}/${item.slug}-${item.id}/${item2?.slug}-${item2?.id}`
                                    }
                                  >
                                    <span>{item2.title}</span>
                                  </Button>
                                </li>
                              ) : null}
                            </>
                          ))}
                        </ul>
                      </div>
                    </>
                  ) : null}
                </>
              ))}
            <div className="megamenu-nav" key="special-desktop">
              {brands?.filter((item) => item.is_featured)?.length ? (
                <>
                  <h5 className={`item`}>Special</h5>
                  <ul className="nav-item-list">
                    {brands
                      ?.filter((item) => item.is_featured)
                      ?.map((item) => (
                        <li
                          key={`${item.slug}-desktop`}
                          className="nav-item special"
                          onClick={handleExit}
                        >
                          <img src={item.image} className="logo-special" alt="" />
                          <Button
                            variant="link"
                            className="p-0"
                            link={`${shop_link}/brand-${item.id}/${showMegaMenu?.data?.slug}/${showMegaMenu?.data?.id}`}
                          >
                            <span className="">{item.title}</span>
                          </Button>
                        </li>
                      ))}
                  </ul>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MegaMenu

// <div className="container">
//    <div className="megamenu-nav-wrapper mt-4">
//       {showMegaMenu?.data?.children?.map(item => (
//          <div className={`megamenu-nav`} key={item.id}>
//             <Button
//                variant="link"
//                className="p-0"
//                link={`${shop_link}/${showMegaMenu?.data?.slug}/${item.slug}-${item.id}`}
//             >
//                {item.title}
//             </Button>
//             <ul className="nav-item-list">
//                {item.children?.map(item2 => (
//                   <li key={item2.id} className="nav-item">
//                      <Button
//                         variant="link"
//                         className="p-0"
//                         onClick={() => alert("SHOW")}
//                         link={`${shop_link}/${showMegaMenu?.data?.slug}/${item.slug}/${item2.slug}/${item2.id}`}
//                      // link={
//                      //   `${item2.link}/${showMegaMenu?.data?.slug}/${item.slug}/${item2.slug}-${item2.id}`
//                      // }
//                      >
//                         <span className="">
//                            {item2.title}
//                         </span>
//                      </Button>
//                   </li>
//                ))}
//             </ul>
//          </div>
//       ))}
//    </div>
// </div>
//         </div >
