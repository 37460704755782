import { variantMapper, variantMapper2 } from "lib/utils/general-utils"
import TagManager from "react-gtm-module"

const sendDataLayer = (sendData, clearPrev) => {
  if (clearPrev) {
    TagManager.dataLayer({
      dataLayer: { ecommerce: null }, // Clear the previous ecommerce object.
    })
  }
  TagManager.dataLayer({
    dataLayer: { ...sendData },
  })
}

const itemsMapper = (data) => {
  return {
    item_id: data?.sku_id || data?.id || data?.product_id,
    item_name: data?.title,
    affiliation: "Monstore Ecommerce",
    currency: data?.currency,
    discount: data?.discount || data?.price - data?.sell_price,
    index: data?.position || 1,
    item_brand: data?.brand?.title || "Monstore",
    item_category: data?.firstcategory?.title,
    item_category2: data?.secondcategory?.title,
    item_category3: data?.thirdcategory?.title,
    item_category4: data?.collection?.title,
    item_list_id: data?.listId || "all_products",
    item_list_name: data?.listName || "All Products",
    item_variant: data?.variant || "All Size",
    price: data?.price,
    quantity: data?.quantity || 1,
  }
}

export const dataLayerEvent = {
  selectContent: (data) => {
    const sendData = {
      event: "select_content",
      content_id: data?.content_id || "interaction",
      content_type: data?.content_type || "Button Click",
      content_name: data?.content_name,
      content_section: data?.content_section,
    }
    sendDataLayer(sendData)
  },
  selectItem: (data, index) => {
    const sendData = {
      event: "select_item",
      ecommerce: {
        item_list_id: data?.listId,
        item_list_name: data?.listName,
        items: [{ ...itemsMapper(data) }],
      },
    }
    sendDataLayer(sendData, true)
  },
  addToCart: (data) => {
    const sendData = {
      event: "add_to_cart",
      ecommerce: {
        currency: data?.currency, //IDR, USD
        value: data?.subtotal,
        items: [{ ...itemsMapper(data) }],
      },
    }
    sendDataLayer(sendData, true)
  },
  removeFromCart: (data) => {
    const sendData = {
      event: "remove_from_cart",
      ecommerce: {
        currency: data?.currency, //IDR, USD
        value: data?.subtotal,
        items: [{ ...itemsMapper(data) }],
      },
    }
    sendDataLayer(sendData, true)
  },
  viewItem: (data) => {
    const sendData = {
      event: "view_item",
      ecommerce: {
        currency: data?.currency, //IDR, USD
        value: data?.price,
        items: [{ ...itemsMapper(data) }],
      },
    }
    sendDataLayer(sendData, true)
  },
  viewItemList: (data) => {
    const sendData = {
      event: "view_item_list",
      ecommerce: {
        item_list_id: data?.listId,
        item_list_name: data?.listName,
        items: [{ ...itemsMapper(data) }],
      },
    }
    sendDataLayer(sendData, true)
  },
  beginCheckout: (data) => {
    const sendData = {
      event: "begin_checkout",
      ecommerce: {
        currency: data?.currency,
        value: data?.grand_total,
        items: data.items.map((item) => itemsMapper(item)),
      },
    }
    sendDataLayer(sendData, true)
  },
  addPaymentInfo: (data) => {
    const sendData = {
      event: "add_payment_info",
      ecommerce: {
        currency: data?.currency,
        value: data?.grand_total,
        coupon: data?.coupon || "None",
        payment_type: data?.payment,
        items: data.items.map((item) =>
          itemsMapper({ ...item, currency: data?.currency })
        ),
      },
    }
    sendDataLayer(sendData, true)
  },
  addShippingInfo: (data) => {
    const sendData = {
      event: "add_shipping_info",
      ecommerce: {
        currency: data?.currency,
        value: data?.grand_total,
        coupon: data?.coupon || "None",
        shipping_tier: data?.shipping,
        items: data.items.map((item) =>
          itemsMapper({ ...item, currency: data?.currency })
        ),
      },
    }
    sendDataLayer(sendData, true)
  },
  purchase: (data) => {
    const sendData = {
      event: "purchase",
      ecommerce: {
        transaction_id: data?.order_no,
        value: data?.total_amount,
        shipping: data?.shipping_fee,
        tax: 0,
        currency: data?.currency,
        coupon: data?.coupon || "None",
        items: data?.details?.map((item, i) =>
          itemsMapper({ ...item, currency: data?.currency, position: i + 1 })
        ),
      },
    }
    sendDataLayer(sendData)
  },
  errorPayment: (data) => {
    dataLayer.push({
      event: "error_payment",
      error_message: data?.errorMessage,
    })
  },
  search: (data) => {
    const sendData = {
      event: "search",
      search_term: data?.search_term,
      search_result: data?.search_result,
    }
    sendDataLayer(sendData)
  },
  loginRegister: (event, method) => {
    sendDataLayer({
      event,
      method,
    })
  },
}
