// ░█▀▀▀ ░█▀▀█ ░█▀▀▀ ░█▀▀▀ 　 ▀▀█▀▀ ░█▀▀▀█ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█▀▀▀ ░█▄▄▀ ░█▀▀▀ ░█▀▀▀ 　 ─░█── ░█──░█ 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█─── ░█─░█ ░█▄▄▄ ░█▄▄▄ 　 ─░█── ░█▄▄▄█ 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be edited. However, please edit the styling on .footer-custom.scss.

// ========================================================================================================================================
// Footer
// ========================================================================================================================================

//Utils
import {
  contact_us_link,
  privacy_policy_link,
  terms_conditions_link,
} from "components/links"

//Components

//Assets
import payment1 from "assets/img/common/logo_bca_1.svg"
import payment3 from "assets/img/common/logo_bca_3.svg"
import payment4 from "assets/img/common/logo_bca_4.svg"

import { dataLayerEvent } from "lib/functions/datalayer-v2"
import { toastHandling } from "lib/utils/toast-handling"
import { useRouter } from "next/router"
import Footer1 from "./component/footer-1"
import Footer2 from "./component/footer-2"

export const Footer = ({ type = 2 }) => {
  const router = useRouter()
  const menuLinks = [
    // { title: "FAQ", link: "/faq" },
    { title: "Contact Us", link: contact_us_link },
    // { title: "Terms & Conditions", link: terms_conditions_link },
    { title: "Privacy Policy", link: privacy_policy_link },
  ]

  const paymentList = [payment1, payment3, payment4]

  const socialList = [
    {
      name: "Instagram",
      icon: "ai-instagram",
      link: "https://www.instagram.com/monstore/",
    },
  ]

  const handleSubmit = (props) => {
    const { status } = props?.data?.subscribe
    toastHandling({
      status: status === "error" ? "danger" : status,
      message:
        status === "error"
          ? "Your email has previously been subscribed to our newsletter, please try another one."
          : "Thank you for subscribing to our newsletter!",
    })
    // if (status !== "error")
    //   eventPush({ path: router.asPath, eventLabel: "Newsletter Submitted" })
  }

  // DataLayer
  const socialDataLayer = (social) => {
    // eventPush({ path: router.asPath, eventLabel: `Footer - ${social.name}` })
    dataLayerEvent.selectContent({
      content_name: social.name,
      content_section: "Footer",
    })
  }

  switch (type) {
    case 1:
      return (
        <Footer1
          menuLinks={menuLinks}
          socialList={socialList}
          paymentList={paymentList}
          socialDataLayer={socialDataLayer}
        />
      )
    case 2:
      return (
        <Footer2
          query
          menuLinks={menuLinks}
          socialList={socialList}
          paymentList={paymentList}
          handleSubmit={handleSubmit}
          socialDataLayer={socialDataLayer}
        />
      )
    default:
      return null
  }
}
