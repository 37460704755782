import { gql } from "@apollo/client"

import {
  UserDataFragment,
  AddressFragment,
  ProductFragment,
  CategoriesFragment,
  CartFragment,
  OrderFragment,
  PaginationFragment,
  OptionsFragment,
  CountryFragment,
  BrandsFragment,
  ProductDetailFragment,
} from "./fragment"

export const Q_AUTH_INITIAL = gql`
   query getMyData($currency: String) {
      me: me {
         ${UserDataFragment}
      }
      addresses: addresses {
         ${AddressFragment}
      }
      categories: categories(filter: { language: "en" }) {
         ${CategoriesFragment}
      }
      carts: carts(language: "en", currency: $currency) {
         ${CartFragment}
      }
      brands {
         ${BrandsFragment}
      }
      collections {
         ${CategoriesFragment}
      }
   }
`
//    me: me {
//       ${UserDataFragment}
//    }
//    addresses: addresses {
//       ${AddressFragment}
//    }
//    wishlists: wishlists(language: "en") {
//       ${ProductFragment}
//    }
//    categories: categories(filter: { language: "en" }) {
//       ${CategoriesFragment}
//    }
//    carts: carts(language: "en", currency: $currency) {
//       ${CartFragment}
//    }
//    countries:countries {
//       ${CountryFragment}
//    }
// brands {
//    ${BrandsFragment}
// }
// }

export const Q_GUEST_INITIAL = gql`
   query getMyGuestData($currency: String!) {
      categories: categories(filter: { language: "en" }) {
         ${CategoriesFragment}
      }
      carts: carts(language: "en", currency: $currency) {
         ${CartFragment}
      }
      brands {
         ${BrandsFragment}
      }
      collections {
         ${CategoriesFragment}
      }
   }
`
// categories: categories(filter: { language: "en" }) {
//    ${CategoriesFragment}
// }
// carts: carts(language: "en", currency: $currency) {
//    ${CartFragment}
// }
// countries:countries {
//    ${CountryFragment}
// }
// brands {
//    ${BrandsFragment}
// }

export const Q_HOME_CONTENT = gql`
  query getMyHomeData {
   collections (filter: { is_featured: 1, language: "en" }) {
      ${CategoriesFragment}
   }
  }
`

export const Q_PRODUCT_DETAIL = gql`
   query PRODUCTDETAIL($id: ID!,  $currency: String) {
      product(language: "en", id: $id, currency: $currency) {
         ${ProductDetailFragment}
      }
   }
`

export const Q_PRODUCT_RELATED = gql`
  query PRODUCTDETAILRELATED($id: ID!, $currency: String) {
    product(language: "en", id: $id, currency: $currency) {
      relateds {
        id
        title
        images
        sell_price
        price
        is_wishlist
        slug
        for_holder
        nft_id
        manage_stock
      }
    }
  }
`

export const Q_SEARCH_CITY_SUBDISTRICT = gql`
  query LOCATION {
    searchCitySubdistrict(s: "") {
      id
      state
      city
      subdistrict
      postcodes
    }
  }
`

export const Q_MY_ORDERS = gql`
   query MY_ORDERS {
   myorders(language: "en") {
      datas {
         ${OrderFragment}
      }
      pagination {
         ${PaginationFragment}
      }
   }
}
`

export const Q_MY_ORDERS_DETAIL = gql`
   query ORDER_DETAIL($id: ID!, ) {
      order(language: "en" id:$id) {
         ${OrderFragment}
      }
   }
`

export const Q_MY_ADDRESS = gql`
   query ADDRESS {
      addresses {
         ${AddressFragment}
      }
   }
`

export const Q_MY_CARTS = gql`
   query MYCARTS($coupon: String, $currency: String) {
      carts(language: "en", coupon: $coupon, currency: $currency) {
         ${CartFragment}
   }
}
`

export const Q_CARTS_WITH_SHIPPING = gql`
   query MYCARTSSHIPPING(
      $delivery_id: Int!,
      $postcode: String!,
      $coupon: String,
      $city: String!,
      $subdistrict: String!,
      $country: String!,
      $currency: String
   ) {
      carts(
         language: "en",
         coupon: $coupon,
         currency: $currency
         shipping: {
            method_id: $delivery_id,
            postcode: $postcode,
            city: $city,
            country: $country,
            subdistrict: $subdistrict
         }
      ) {
         ${CartFragment}
      }
   }
`

export const Q_DELIVERY_METHOD = gql`
  query SHIPPING_METHOD(
    $country: String!
    $city: String!
    $subdistrict: String!
    $postcode: String!
    $currency: String
  ) {
    shippingmethods(
      currency: $currency
      shipping: {
        shipping_country: $country
        shipping_city: $city
        shipping_subdistrict: $subdistrict
        shipping_postcode: $postcode
      }
    ) {
      id
      name
      service_name
      insurance_fee
      price
    }
  }
`

export const Q_CATEGORIES = gql`
   query CATEGORIES {
      categories(filter: { language: "en" }) {
         ${CategoriesFragment}
      }
   }
`
export const Q_CATALOGS = gql`
   query CATALOGS(
      $category_id: Int,
      $collection_id: Int,
      $is_featured: Int,
      $price: String,
      $limit: Int,
      $page: Int,
      $s: String,
      $options: [OptionFilter],
      $currency: String
      $brand_id: Int
      $nft_ids: [String]
      $sort: String
   ) {
      catalogs(filter: {
         language: "en",
         category_id: $category_id,
         collection_id: $collection_id,
         is_featured: $is_featured,
         price: $price,
         limit: $limit,
         page: $page,
         s: $s,
         options: $options,
         currency: $currency,
         brand_id: $brand_id,
         nft_ids: $nft_ids
         sort: $sort
      }) {
         products {
            ${ProductFragment}
         }
         pagination {
            ${PaginationFragment}
         }
         options {
            ${OptionsFragment}
         }
      }
   }
`

export const Q_COUNTRIES = gql`
  query Country {
   countries {
    ${CountryFragment}
  }
}
`

export const Q_PAYMENT = gql`
  query PAYMENT {
    paymentmethods {
      title
      logo
      fee_pct
      fee_flat
    }
  }
`

export const Q_CHECKOUT_REQUIRED = gql`
   query CheckoutData( $currency: String) {
      carts: carts(language: "en" currency: $currency) {
         ${CartFragment}
      }
      addresses: addresses {
         ${AddressFragment}
      }
   }
`

export const Q_STOCK = gql`
  query STOCK($id: ID!) {
    stock(sku_id: $id) {
      stock
      status
      message
      price
      sell_price
    }
  }
`

export const Q_PAGES = gql`
  query PAGES {
    pages(language: "en") {
      title
      id
      content
      slug
    }
  }
`

export const Q_ORDER_DETAIL = gql`
   query ORDER_DETAIl( $id: ID!) {
      order(language: "en", id: $id) {
         ${OrderFragment}
      }
   }
`

export const Q_FAQ = gql`
  query FAQS {
    faqs(language: "en") {
      id
      title
      description
    }
  }
`

export const Q_BLOGS = gql`
  query BLOGS($isFeatured: Boolean, $category_id: Int) {
    blogs(language: "en", isFeatured: $isFeatured, category_id: $category_id) {
      datas {
        id
        title
        slug
        image
        excerpt
        publish_date
        tags {
          id
          name
        }
      }
    }
  }
`

export const Q_BLOGS_CATEGORY = gql`
  query BLOGS_CATEGORY {
    blogcategories(language: "en") {
      id
      title
    }
  }
`

export const Q_BLOG = gql`
  query BLOG($id: ID!) {
    blog(language: "en", id: $id) {
      id
      image
      title
      publish_date
      content
      tags {
        id
        name
      }
    }
    blogs(language: "en") {
      datas {
        id
        title
        slug
        image
        excerpt
        publish_date
        tags {
          id
          name
        }
      }
    }
  }
`

export const Q_BRAND_COLLECTION = gql`
  query BRAND_COLLECTION($id: ID!, $currency: String) {
   product(language: "en", id: $id, currency: $currency) {
      id
      slug
      firstcategory {
         id
         title
         slug
      }
      secondcategory {
         id
         title
         slug
      }
      thirdcategory {
         id
         title
         slug
      }
      collection {
         ${CategoriesFragment}
      }
      brand {
        ${BrandsFragment}
      }
    }
  }
`
