import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension"
import { useMemo } from 'react';

//Reducers
import { combineReducers } from 'redux';
import AuthReducer from './authReducer';
import GeneralReducer from './generalReducer';

let store

const initialState = {}

//ROOT REDUCERS
const rootReducer = combineReducers({
   general: GeneralReducer,
   auth: AuthReducer,
})

function initStore(preloadedState = initialState) {
   return createStore(
      rootReducer,
      preloadedState,
      composeWithDevTools(applyMiddleware(thunk))
   )
}

export const initializeStore = (preloadedState) => {
   let _store = store ?? initStore(preloadedState)
   if (preloadedState && store) {
      _store = initStore({
         ...store.getState(),
         ...preloadedState,
      })
      store = undefined
   }
   if (typeof window === "undefined") return _store
   if (!store) store = _store
   return _store
}

export function useStore(initialState) {
   const store = useMemo(() => initializeStore(initialState), [initialState])
   return store
};

//Export all type
export * from './type';
