import React from "react"

//Components
import { Link } from "../index"

//Assets
import logoLight from "assets/img/common/logo_main-light-alt.png"
import logoDark from "assets/img/common/logo_main-dark-alt.png"

export const NavbarCheckout = ({
  navExpand = "lg",
  navTheme = "navbar-light",
  navPosition = "fixed-top",
}) => {
  return (
    <div
      className={`navbar ${navTheme} navbar-expand-${navExpand} ${navPosition}`}
    >
      <div className="navbar-main">
        <div className="container justify-content-center">
          <div className="col-menu">
            <Link className="navbar-brand" to="/">
              <img
                src={logoLight}
                className="logo-light img-fluid"
                alt="Logo"
              />
              <img src={logoDark} className="logo-dark img-fluid" alt="Logo" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
